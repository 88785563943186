import * as React from 'react';
import * as classnames from 'classnames/bind';

const styles = require('./CareersLink.less');
const cx = classnames.bind(styles) as any;

export class CareersLink extends React.Component<any, any>
{
    render()
    {
        return  <a className={cx('careersLink')} href="mailto:">careers@ergalio.net</a>;
    }
}